.wrap h3 {
  @apply mb-4;
}

.content {
  @apply flex flex-col md:flex-row justify-between gap-6;
}

.content.right {
  @apply md:flex-row-reverse;
}

.body {
  @apply w-full;
}

.img {
  @apply relative w-full md:w-1/3 h-64 md:h-auto;
}

.content.has_image .body {
  @apply w-full md:w-2/3;
}

